import React from 'react';
import { useTranslation } from 'react-i18next';

import CampaignsFanHub from '@/components/campaigns-page/CampaignsFanHub';
import { FanHubDefaultCard } from '@/components/cards/FanHubDefaultCard';
import FanHubTabbedPreview from '@/components/promote-modals/WebsiteGeneratorModal/website-generator-steps/FanHubTabbedPreviews';
import useWebsite from '@/hooks/website-builder/useWebsite';

import ScrollToTopButton from '../components/utility/buttons/ScrollToTopButton';
import Loading from '../components/utility/Loading';
import UserHeader from '../components/utility/navigation/UserHeader';

const FanHubPage = () => {
  const { t } = useTranslation();
  const { website, websiteError, websiteIsLoading } = useWebsite();

  return (
    <div data-testid="fan-hub-page" className="page-content">
      <UserHeader title={t('NAVIGATION.FAN-HUB')} />
      <ScrollToTopButton />
      {websiteIsLoading && (
        <div className="centered-loading">
          <Loading />
        </div>
      )}
      <div className="mt10 pos-rel">
        {website && (
          <>
            <FanHubTabbedPreview />
            <CampaignsFanHub />
          </>
        )}
        {!website && websiteError && (
          <div className="mt10">
            <FanHubDefaultCard />
          </div>
        )}
      </div>
    </div>
  );
};

export default FanHubPage;
